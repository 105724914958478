<template>
  <div class="assetCenterInfo" v-loading="loading">
    <div class="box">
      <div class="border">
        <el-form style="font-size: 20px;padding:25px 30px ;">
          总金额
          <el-tooltip class="item" effect="dark" content="总金额=可提现金额+提现中金额+待结算金额（待结算金额：等待结算的未确定收入）" placement="right">
            <i class="el-icon-warning-outline"></i>
          </el-tooltip>
        </el-form>
        <el-form style="font-size: 28px;padding:5px 23px;">￥{{totalAmountData.totalAmount}}</el-form>
        <router-link to="/moneyFlow">
          <el-button size="small" type="primary" style="margin: 20px;">全部流水</el-button>
        </router-link>
      </div>

      <div class="border">
        <el-form style="font-size: 20px;padding:25px 30px ;">
          可提现
          <el-tooltip class="item" effect="dark" content="已结算金额" placement="right">
            <i class="el-icon-warning-outline"></i>
          </el-tooltip>
        </el-form>
        <el-form style="font-size: 28px;padding:5px 23px;">￥{{totalAmountData.balance}}</el-form>

        <div class="inline-block">
          <el-tooltip class="item" effect="dark" content="银行卡资料修改审核中,请等待银行卡资料修改完成" placement="bottom" v-if="isUpdateBankInfo">
            <el-button size="small" style="margin: 20px;">去提现</el-button>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="请先完成开店入驻" placement="bottom" v-else-if="totalAmountData.certificationStatus === 2">
            <el-button size="small" style="margin: 20px;">去提现</el-button>
          </el-tooltip>
          <el-button
            v-else
            size="small"
            type="primary"
            style="margin: 20px;"
            @click="(() => {centerDialogVisible = true;activeName = '0';getSupplierInfo()})"
          >去提现</el-button>

        </div>

        <el-button
          :disabled="totalAmountData.certificationStatus === 2"
          size="small"
          type="primary"
          style="margin: 5px;"
          @click="(() => {centerDialogVisible = true;activeName = '1';getSupplierInfo()})"
        >提现记录</el-button>
      </div>

      <div class="border">
        <el-form
          style="font-size: 20px;padding:50px 48px ;"
        >待结算
        <el-tooltip class="item" effect="dark" content="等待结算的未确定收入" placement="top-start">
          <i class="el-icon-warning-outline"></i>
        </el-tooltip>
         ￥{{totalAmountData.waitSettleAmount}}</el-form>
        <el-form
          style="font-size: 20px;padding-left: 15px;"
        >提现中金额
        <el-tooltip class="item" effect="dark" content="提现中待审核金额" placement="top-start">
          <i class="el-icon-warning-outline"></i>
        </el-tooltip>
        ￥{{totalAmountData.withdrawlingAmount}}</el-form>
      </div>

      <div class="border flex flex-column">
        <el-form style="font-size: 20px;padding:25px 30px ;display: flex;justify-content: space-between;align-items: center;"><span>提现账户</span><el-button @click="withdrawalAccountAlert = true" type="primary" size="mini">新增</el-button></el-form>
        <div ref="accountDivWrap" class="wrap_border_ls flex-1">
          <div ref="accountDiv" style="display: inherit;position: absolute;" :style="{left:accountDivLeft+'px',cursor:accountDivCursor}"
             @mousedown="accountDivDown" @mousemove="accountDivMove" @mouseleave="accountDivLeave" @mouseup="accountDivUp">
            <div class="inline_block mrt-r-15" v-if="totalAmountData.isBindWx == 1">
              <div class="img_src_bank"><img src="../../../../public/images/account/weChat.png" alt="" draggable="false"><span>微信零钱</span></div>
              <div class="flex_between">
                <img :src="totalAmountData.wxAvatarUrl" alt="" draggable="false">
                <span>{{totalAmountData.wxName}}</span>
              </div>
            </div>
            <div class="inline_block" v-if="totalAmountData.certificationStatus == 1">
              <div class="img_src_bank"><img src="../../../../public/images/account/bank.png" alt="" draggable="false"><span>{{bankNameAlign}}</span></div>
              <div class="flex_between">
                <span>{{totalAmountData.certificationStatus === 1?totalAmountData.bankCard:'未绑定提现账户，绑定后可提现至该账户'}}</span>
              </div>
            </div>
            <div class="margin_auto">
              <el-button size="small" v-show="totalAmountData.certificationStatus === 2" type="primary">
                <router-link tag="div" to="/applyOpenStore">去认证</router-link>
              </el-button>
            </div>
          </div>
        </div>
      </div>

      <div class="border">
        <el-form class="ef_form_flex">
          <span>收支概况</span>
          <span>
            <router-link to="/getSupplierBills" tag="div">查看更多 ></router-link>
          </span>
        </el-form>
        <el-form
          style="font-size: 16px;padding:5px 23px;"
        >收入:{{radio == '今日'?totalAmountData.todayIncomeAmount:totalAmountData.yesterdayIncomeAmount}}</el-form>
        <el-form
          style="font-size: 16px;padding:5px 23px;"
        >支出:{{radio == '今日'?totalAmountData.todayExpensesAmount:totalAmountData.yesterdayExpensesAmount}}</el-form>
        <div style="text-align:center;">
          <el-radio-group v-model="radio" size="small">
            <el-radio-button label="今日"></el-radio-button>
            <el-radio-button label="昨日"></el-radio-button>
          </el-radio-group>
        </div>
      </div>

      <div class="border">
        <el-form style="font-size: 20px;padding:25px 30px ;">账户信息</el-form>
        <div class="wrap_img" v-show="totalAmountData.certificationStatus == 1">
          <img :src="userInfo.userLogo" />
        </div>
        <div v-show="totalAmountData.certificationStatus == 1" class="margin_auto">{{userInfo.userName}}</div>
        <div v-show="totalAmountData.certificationStatus == 2" class="text_center_certification">
          <el-button size="small" type="primary">
            <router-link tag="div" to="/applyOpenStore">去认证</router-link>
          </el-button>
        </div>
      </div>
    </div>

    <el-dialog title :visible.sync="centerDialogVisible" width="900px" center :close-on-press-escape="false" :close-on-click-modal="false" :wrapperClosable="false">
      <el-tabs v-model="activeName">
        <el-tab-pane label="提现" name="0">
          <el-form ref="form" :model="form" label-width="100px">
            <el-form-item label="店铺名称：">
              <el-col>{{userInfo.userName}}</el-col>
            </el-form-item>
            <el-form-item label="可提现金额：">
              <el-col>{{totalAmountData.balance}}元</el-col>
            </el-form-item>
            <el-form-item label="本次提现：">
              <el-col :span="7" class="withdraw">
                <el-input
                  placeholder="请输入提现金额"
                  v-model="form.amount"
                  @change="WithdrawalChange"
                  type="number"
                ></el-input>
                <span class="verification">单次提现100起提</span>
              </el-col>
              <el-col style="margin-left:10px" :span="5">
                <el-button @click="allWithdrawal">全部提现</el-button>
              </el-col>
            </el-form-item>
            <el-form-item label="到账账户：">
                <div>
                  <div style="display: inline-block;">
                    <div class="border_line_box" v-if="form.type == 1">
                      <div class="account_flex">
                        <img src="../../../../public/images/account/bank.png" alt=""> <span>{{totalAmountData.bankCard}}</span>
                      </div>
                    </div>
                    <div class="border_line_box" v-if="form.type == 2">
                      <div class="account_flex">
                        <img style="border-radius: 50%" class="weChat_avatar" :src="totalAmountData.wxAvatarUrl" alt=""> <span>{{totalAmountData.wxName}}</span>
                      </div>
                    </div>
                  </div>
                  <div style="display: inline-block;margin-left: 10px;">
                    <el-button @click="replaceType">更换</el-button>
                  </div>
                </div>
            </el-form-item>
            <el-form-item label="短信验证码：">
              <el-col :span="12" class="withdraw">
                <el-input placeholder="请输入验证码" v-model="form.phoneCode">
                  <el-button slot="append" @click="getVerification">{{code}}</el-button>
                </el-input>
                <span
                  class="verification"
                >验证短信将发送到你登录的手机：+86 {{phoneNumberShow(supplierInfo.phone)}}，请注意查收</span>
              </el-col>
            </el-form-item>
            <el-form-item class="labelRemarksAssetCenter" label="备注：">
              <el-col :span="12">
                <el-input
                  type="textarea"
                  placeholder="请输入内容"
                  v-model="form.remark"
                  maxlength="50"
                  show-word-limit
                ></el-input>
              </el-col>
            </el-form-item>

            <el-form-item>
              <el-button @click="cancelWithdrawal">取 消</el-button>
              <el-button type="primary" @click="confirmWithdrawal">确定提现</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>

        <el-tab-pane label="提现记录" name="1">
          <el-button type="text" style="position: absolute;right: 0;top: 0;z-index: 50;padding-top: 15px;" @click="downloadExcel">导出</el-button>
          <template>
            <el-table :data="tableData" height="50vh" style="width: 100%">
              <el-table-column label="序号" width="60">
                <template slot-scope="scope">
                  {{scope.$index + 1 + (currentPage - 1) * pageSize}}
                </template>
              </el-table-column>
              <el-table-column prop="createTime" width="100" label="申请时间"></el-table-column>
              <el-table-column prop="id" label="提现流水号"></el-table-column>
              <el-table-column prop="applyAmount" label="金额（元）"></el-table-column>
              <el-table-column  label="提现到账账户" width="180">
                <template slot-scope="scope">
                  <div v-if="scope.row.type == 1">银行卡：{{scope.row.bankCard}}</div>
                  <div class="flex" v-if="scope.row.type == 2" style="width: 180px;">
                    <el-image class="" style="width: 80px;height: 80px;float:left;" :src="scope.row.avatarUrl"></el-image>
                    <div class="flex-1 padding-left-10 padding-right-30 flex flex-column" style="float:left;width:150px;">
                      <div class="goods-name flex-1">{{scope.row.nickName}}</div>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="状态">
                <template slot-scope="scope">{{withdrawStatus(scope.row.status)}}</template>
              </el-table-column>
              <el-table-column prop="supplierRemark" label="备注"></el-table-column>
            </el-table>
          </template>
          <template>
            <div class="paging-row">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[10, 15, 20]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalListNum"
              ></el-pagination>
            </div>
          </template>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>

    <!-- 修改银行卡信息 -->
    <el-dialog title="银行信息" :visible.sync="bankInfoAlert" width="60%">
      <el-form label-position="top" label-width="80px" :model="formLabelAlign">
        <el-form-item label="账户名">
          <el-col :span="16" class="withdraw">
            <el-input disabled v-model="formLabelAlign.bankHolderName"></el-input>
            <span class="verification">开户名称必须与店铺绑定的主体一致</span>
          </el-col>
        </el-form-item>
        <el-form-item label="银行账号">
          <el-col :span="16" class="withdraw">
            <el-input v-model="formLabelAlign.bankCard" placeholder="请填写银行账号"></el-input>
            <span class="verification">请正确填写银行卡号，填写错误将会导致提现失败</span>
          </el-col>
        </el-form-item>
        <el-form-item label="开户银行">
          <el-col :span="16">
            <el-input v-model="formLabelAlign.bankName" placeholder="请填写开户银行,如中国银行"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="开户支行">
          <el-col :span="16">
            <el-input v-model="formLabelAlign.bankBranch" placeholder="请填写开户支行,如深圳西乡支行"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item class="labelRemarksAssetCenter">
          <el-button @click="cancelBankModify">取消</el-button>
          <el-button type="primary" @click="confirmBankModify">提交审核</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 提现账户 -->
    <el-dialog title="提现账户" :visible.sync="withdrawalAccountAlert" width="700px">
      <div style="padding-bottom: 30px; text-align: center;">
        <div class="my_title">我的账户</div>
        <div class="my_account">
          <div class="image_bank"><img src="../../../../public/images/account/bank.png" alt=""><span class="right_bank">{{bankNameAlign?bankNameAlign:'银行卡'}}</span></div>
          <div class="bnt_bank">
            <el-button size="small" v-show="isUpdateBankInfo">审核中...</el-button>
            <div v-show="totalAmountData.certificationStatus === 1 && !isUpdateBankInfo && totalAmountData.bankCard">{{totalAmountData.bankCard}}</div>
            <el-button type="primary" v-show="totalAmountData.certificationStatus === 1 && !isUpdateBankInfo && !totalAmountData.bankCard" size="small" @click="bankInfoAlert = true">绑定银行卡</el-button>
          </div>
          <div class="modify_bank" @click="bankInfoAlert = true">修改</div>
        </div>
        <div class="my_account">
          <div class="image_bank"><img src="../../../../public/images/account/weChat.png" alt=""><span class="right_bank">微信</span></div>
          <div class="flex_between" v-if="totalAmountData.isBindWx == 1">
            <img :src="totalAmountData.wxAvatarUrl" alt="" draggable="false">
            <span>{{totalAmountData.wxName}}</span>
          </div>
          <div class="bnt_bank" v-else>
            <el-button type="primary" size="small" @click="withdrawalWeChat = true">绑定微信</el-button>
          </div>
          <div class="modify_bank" v-if="totalAmountData.isBindWx == 1" @click="untieWeChat">解绑</div>
        </div>
      </div>
    </el-dialog>

    <!-- 微信支付 -->
    <el-dialog title="微信支付" :visible.sync="withdrawalWeChat" width="70%">
      <div>
        <div class="weChat_process">微信支付绑定流程</div>
        <div class="applets_code">微信扫码下方小程序二维码，登录商家账户；进入我的-账户资产-提现账户，点击绑定微信支付授权，授权完成即可绑定成功</div>
        <img class="weChat_image" src="../../../../public/images/account/process_image.png" alt="">
        <div class="description">注：请确保使用法人/经营者微信扫码登录绑定，如绑定非法人/经营者微信，可能导致提现风险！</div>
      </div>
      <div style="text-align: center;"><el-button @click="withdrawalWeChat = false" size="small">取消</el-button></div>
    </el-dialog>

    <!-- 到账账户 -->
    <el-dialog title="到账账户" :visible.sync="toAccount" width="700px">
      <div style="text-align: center">
        <div class="my_account cursor" @click="to_account(1)" v-if="totalAmountData.certificationStatus == 1 && totalAmountData.bankCard">
          <div class="el_radio_right"><el-radio v-model="form.type" :label="1"><span style="color: #ffffff;">.</span></el-radio></div>
          <div class="image_bank"><img src="../../../../public/images/account/bank.png" alt=""><span class="right_bank">银行卡</span></div>
          <div class="bnt_bank">
            {{totalAmountData.bankCard}}
          </div>
        </div>
        <div class="my_account cursor" @click="to_account(2)"  v-if="totalAmountData.isBindWx == 1">
          <div class="el_radio_right"><el-radio v-model="form.type" :label="2"><span style="color: #ffffff;">.</span></el-radio></div>
          <div class="image_bank"><img src="../../../../public/images/account/weChat.png" alt=""><span class="right_bank">微信</span></div>
          <div class="bnt_bank flex_center_ver">
            <img class="weChat_avatar" :src="totalAmountData.wxAvatarUrl" alt="">
            <span>{{totalAmountData.wxName}}</span>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
const utils = require("../../../assets/js/utils.js");
import {plusDownloadExcelTask} from "../../../store/excel";
import { URL} from "../../../config";

export default {
  components: {},
  data() {
    return {
      code: "获取验证码",
      form: {
        amount: "",
        remark: "",
        phoneCode: "",
        type: 1 // 1银行卡 2微信
      },
      currentPage: 1, // 当前页码
      totalListNum: 0, // 列表总条量
      pageSize: 10, // 每页显示得条数
      tableData: [],
      activeName: "0",
      radio: "今日",
      totalAmountData: "",
      userInfo: "",
      centerDialogVisible: false,
      timer: null,
      supplierInfo: "",
      bankInfoAlert: false,
      isUpdateBankInfo: false,
      withdrawalAccountAlert: false,
      withdrawalWeChat: false,
      toAccount: false,
      bankNameAlign: '',
      formLabelAlign: {
        bankHolderName: "",
        bankName: "",
        bankBranch: "",
        bankCard: ""
      },
      accountDivStatus:false,//鼠标是否点进去移动了
      accountDivLeft:30,// 30 到 -175 即可
      accountDivLeftBegin:0,
      accountDivCursor:'auto',
    };
  },
  created() {
    let info = this.$store.state.userInfo;
    this.userInfo = info;
    this.information();
    // 获取商家可提现的基本信息
    // this.getSupplierInfo();
    // 获取商家提现记录
    this.getSupplierWithdrawList();
    // 获取商家银行卡信息
    this.getSupplierSettlement();
  },
  mounted(){
    // 禁止拖拽
    this.$refs.accountDiv.onselectstart = function () {
      return false
    };
  },
  watch: {
    centerDialogVisible(newVal, oldVal) {
      if (!newVal) {
        this.cancelWithdrawal();
      }
    }
  },
  methods: {
    // 解绑微信
    untieWeChat() {
      var _this = this;
      this.$confirm('确定解除微信绑定吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.loading = true;
        _this.axios
          .post(URL.supplier.unBindWx)
          .then(res => {
            _this.$message({
              message: "解绑成功！",
              type: "success"
            });
            _this.withdrawalAccountAlert = false;
            _this.loading = false;
            _this.information();
          })
          .catch(res => {
            _this.loading = false;
          })
          .finally(res => {
            _this.loading = false;
          });
      }).catch(() => {

      });
    },
    to_account(type) {
      this.form.type = type;
      this.toAccount = false;
    },
    replaceType() {
      this.toAccount = true;
    },
    withdrawStatus(status) {
      let message = "";
      switch (status) {
        case 1:
          message = "申请中";
          break;
        case 2:
          message = "审核通过";
          break;
        case 3:
          message = "打款成功";
          break;
        case 4:
          message = "审核拒绝 ";
          break;
      }
      return message;
    },
    // 手机号*展示
    phoneNumberShow(num) {
      var num1 = "";
      if (num) {
        num1 = num.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2");
      }
      return num1;
    },
    // 获取验证码
    getVerification() {
      var _this = this;
      if (this.code != "获取验证码") return;
      if (!this.form.amount) {
        this.$message({
          message: "请输入提现金额哦~",
          type: "warning"
        });
        return false;
      }

      this.loading = true;
      return new Promise((resolve, reject) => {
        this.axios
          .post(URL.suppWallet.sendSupplierWithdrawlsCode)
          .then(res => {
            this.loading = false;
            _this.$message({
              message: "验证码发送成功，请注意查收~",
              type: "success"
            });
            _this.code = 120;
            _this.timer = setInterval(() => {
              if (_this.code === 1) {
                clearInterval(_this.timer);
                _this.code = "获取验证码";
              } else {
                _this.code--;
              }
            }, 1000);
            if (res.data.phoneCode) {
                this.$message.success("测试环境自动显示验证码,别提BUG！~");
                this.form.phoneCode = res.data.phoneCode;
              }
            resolve(res);
          })
          .catch(res => {
            this.loading = false;
          })
          .finally(res => {
            this.loading = false;
          });
      });
    },
    WithdrawalChange(item) {
      // 获取小数点后两位
      let amount = Math.floor(item * 100) / 100;
      // 当输入提现金额大于可提现金额时
      if (amount > this.totalAmountData.balance) {
        this.form.amount = this.totalAmountData.balance;
      } else if (amount < 100) {
        this.form.amount = 100;
        this.$message({
          message: "单次提现金额100起提",
          type: "warning"
        });
      } else {
        this.form.amount = amount;
      }
    },
    /**
     * 全部提现
     */
    allWithdrawal() {
      this.form.amount = this.totalAmountData.balance;
    },
    // 取消提现
    cancelWithdrawal() {
      this.centerDialogVisible = false;
      clearInterval(this.timer);
      this.code = "获取验证码";
      this.form = {
        amount: "",
        remark: "",
        phoneCode: "",
        type: 1
      };
    },
    // 取消银行卡修改
    cancelBankModify() {
      this.bankInfoAlert = false;
      this.formLabelAlign = {
        bankHolderName: "",
        bankName: "",
        bankBranch: "",
        bankCard: ""
      };
    },
    // 银行卡修改
    confirmBankModify() {
      let { bankName, bankBranch, bankCard } = this.formLabelAlign;
      if (this.loading) return false;
      if (!bankName || !bankBranch || !bankCard) {
        this.$message({
          message: "请完善银行信息哦~",
          type: "warning"
        });
        return false;
      }

      let data = {
        bankName,
        bankBranch,
        bankCard
      };

      this.loading = true;
      return new Promise((resolve, reject) => {
        this.axios
          .post(URL.suppWallet.saveSupplierSettlementReview, data)
          .then(res => {
            this.loading = false;
            this.$message({
              message: "提交成功，资料正在审核中,请等待1-2个工作日！",
              type: "success"
            });
            this.isUpdateBankInfo = true;
            this.cancelBankModify();
            resolve(res);
          })
          .catch(res => {
            this.loading = false;
          })
          .finally(res => {
            this.loading = false;
          });
      });
    },
    // 提现金额格式化
    getFloatStr(num) {
      num += "";
      num = num.replace(/[^0-9|\.]/g, ""); //清除字符串中的非数字非.字符

      if (/^0+/)
        //清除字符串开头的0
        num = num.replace(/^0+/, "");
      if (!/\./.test(num))
        //为整数字符串在末尾添加.00
        num += ".00";
      if (/^\./.test(num))
        //字符以.开头时,在开头添加0
        num = "0" + num;
      num += "00"; //在字符串末尾补零
      num = num.match(/\d+\.\d{2}/)[0];
      return num;
    },
    /**
     * 确认提现
     */
    confirmWithdrawal() {
      if (this.loading) return false;
      if (this.form.amount > this.totalAmountData.balance) {
        this.$message({
          message: "提现金额不能大于可提现金额哦~",
          type: "warning"
        });
        return false;
      }

      if (!this.form.amount || parseFloat(this.form.amount) < 100) {
        this.$message({
          message: "请输入大于100的提现金额哦~",
          type: "warning"
        });
        return false;
      }

      if (!this.form.phoneCode) {
        this.$message({
          message: "请输入验证码~",
          type: "warning"
        });
        return false;
      }

      this.loading = true;
      this.form.amount = this.getFloatStr(this.form.amount);
      let data = Object.assign({}, this.form);
      return new Promise((resolve, reject) => {
        this.axios
          .post(URL.suppWallet.withdrawlsSupplierWallet, data)
          .then(res => {
            this.loading = false;
            this.$message({
              message: "提现成功！",
              type: "success"
            });
            this.information();
            this.cancelWithdrawal();
            resolve(res);
          })
          .catch(res => {
            this.loading = false;
          })
          .finally(res => {
            this.loading = false;
          });
      });
    },
    /**
     * pageSize 改变时会触发：选择每页显示条数
     */
    handleSizeChange(val, type) {
      this.pageSize = val;
      this.currentPage = 1;
      this.getSupplierWithdrawList();
    },
    /**
     * currentPage 改变时会触发：选择页数
     */
    handleCurrentChange(val, type) {
      this.currentPage = val;
      this.getSupplierWithdrawList();
    },
    information() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        this.axios
          .post(URL.suppWallet.getAssetCenterInfo)
          .then(res => {
            this.loading = false;
            this.totalAmountData = res.data;
            resolve(res);
          })
          .catch(res => {
            this.loading = false;
          })
          .finally(res => {
            this.loading = false;
          });
      });
    },
    getSupplierInfo() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        this.axios
          .get(URL.suppWallet.getSupplierWalletDetails)
          .then(res => {
            this.loading = false;
            this.supplierInfo = res.data;
            resolve(res);
          })
          .catch(res => {
            this.loading = false;
          })
          .finally(res => {
            this.loading = false;
          });
      });
    },
    getSupplierWithdrawList() {
      console.log(this.userInfo)
      let params = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        supplierId: this.userInfo.id,
        supplierName: this.userInfo.userName
      };
      this.loading = true;
      return new Promise((resolve, reject) => {
        this.axios
          .get(URL.suppWallet.getSupplierWithdrawlsList, { params })
          .then(res => {
            this.loading = false;
            res.data.records.forEach(item => {
              item.createTime = utils.formatTime(item.createTime);
            });
            this.totalListNum = res.data?.total;
            this.tableData = res.data?.records;
            resolve(res);
          })
          .catch(res => {
            this.loading = false;
          })
          .finally(res => {
            this.loading = false;
          });
      });
    },
    getSupplierSettlement() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        this.axios
          .get(URL.suppWallet.getSupplierSettlement)
          .then(res => {
            this.loading = false;
            this.formLabelAlign.bankHolderName = res.data.bankHolderName;
            this.bankNameAlign = res.data.bankName;
            this.isUpdateBankInfo = res.data.isUpdate === 1;
            resolve(res);
          })
          .catch(res => {
            this.loading = false;
          })
          .finally(res => {
            this.loading = false;
          });
      });
    },
    accountDivDown(e){
      if(this.$refs.accountDiv.scrollWidth > this.$refs.accountDivWrap.clientWidth){
        this.accountDivStatus = true;
        e = e || window.event;
        var x = 0;
        if (!e.touches) {  //兼容PC端
          x = e.clientX;
        } else {
          x = e.touches[0].pageX;//兼容移动端
        }
        this.accountDivLeftBegin = x;
        this.accountDivCursor = 'move';
      }
    },
    accountDivMove(e){
      e = e || window.event;
      if(this.accountDivStatus){
        var x = 0;
        if (!e.touches) {  //兼容PC端
          x = e.clientX;
        } else {
          x = e.touches[0].pageX;//兼容移动端
        }
        let mx = x - this.accountDivLeftBegin;
        this.accountDivLeft = this.accountDivLeft + mx;
        this.accountDivLeftBegin = x;
        if(this.accountDivLeft > 30){
          this.accountDivLeft = 30;
          return false;
        }
        if (this.accountDivLeft < -125){
          this.accountDivLeft = -125;
          return false;
        }
      }
    },
    accountDivLeave(e){
      this.accountDivStatus = false;
      this.accountDivCursor = 'auto';
    },
    accountDivUp(e){
      this.accountDivStatus = false;
      this.accountDivCursor = 'auto';
    },
    /**
     * 导出excel
     */
    downloadExcel() {
      let data = {
        name: "提现记录报表",
        params: {
          excelType: 1008,
          excelParameterJson: JSON.stringify({}),
        },
      };
      plusDownloadExcelTask.call(this, data);
    },
  }
};
</script>

<style lang="less" scoped>
.assetCenterInfo {
  .weChat_avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .border_line_box {
    width: 220px;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    .account_flex {
      img {
        margin-right: 10px;
        vertical-align: middle;
      }
      span {
        vertical-align: middle;
      }
    }
  }
  .my_title {
    border-top: 1px solid #dedede;
    font-weight: 600;
    padding: 15px 0;
    text-align: left;
  }
  .cursor {
    cursor: pointer;
  }
  .my_account {
    display: inline-block;
    width: 280px;
    height: 150px;
    border: 1px solid #dedede;
    border-radius: 5px;
    margin-right: 20px;
    vertical-align: middle;
    padding-top: 35px;
    box-sizing: border-box;
    position: relative;
    .flex_between {
      display: flex;
      align-items: center;
      padding: 0 15px;
      margin-top: 10px;
      img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-right: 10px;
      }
    }
    .modify_bank {
      position: absolute;
      top: 41px;
      right: -1px;
      display: inline-block;
      width: 45px;
      height: 20px;
      line-height: 20px;
      font-size: 12px;
      text-align: center;
      cursor: pointer;
      border-top: 1px solid #dedede;
      border-left: 1px solid #dedede;
      border-bottom: 1px solid #dedede;
      border-radius: 3px 0 0 3px;
      &:hover {
        opacity: 0.8;
        color: #07A675;
        background: #ecf5ff;
        border-top-color: #b3d8ff;
        border-left-color: #b3d8ff;
        border-bottom-color: #b3d8ff;
      }
    }
    .el_radio_right {
      position: absolute;
      right: 0px;
      top: 10px;
    }
    .image_bank {
      display: flex;
      align-items: center;
      padding-left: 15px;
      .right_bank {
        margin-left: 20px;
      }
    }
    .bnt_bank {
      text-align: center;
      margin-top: 15px;
    }
    .flex_center_ver {
      padding: 0 15px;
      box-sizing: border-box;
      img {
        vertical-align: middle;
        margin-right: 10px;
      }
      span {
        vertical-align: middle;
      }
    }
  }
  .weChat_process {
    font-size: 14px;
    color: #606266;
  }
  .applets_code {
    font-size: 12px;
    color: #909399;
    margin-top: 5px;
  }
  .weChat_image {
    width: 100%;
  }
  .description {
    font-size: 15px;
    color: red;
    margin: 10px 0;
  }
  .box {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    .border {
      width: 30%;
      height: 200px;
      background: #fff;
      border-radius: 8px;
      margin-left: 30px;
      margin-bottom: 20px;
      .wrap_border_ls {
        width: 100%;
        padding: 0 30px;
        display: flex;
        flex-wrap: nowrap;
        box-sizing: border-box;
        overflow: hidden;
        position: relative;
        .mrt-r-15 {
          margin-right: 15px;
        }
        .inline_block {
          display: inline-block;
          width: 220px;
          padding: 15px 0;
          border: 1px solid #dedede;
          border-radius: 5px;
          box-sizing: border-box;
          .img_src_bank {
            padding-left: 15px;
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            img {
              margin-right: 10px;
            }
          }
          .flex_between {
            display: flex;
            // justify-content: space-between;
            align-items: center;
            padding: 0 15px;
            margin-top: 10px;
            img {
              width: 32px;
              height: 32px;
              border-radius: 50%;
              margin-right: 10px;
            }
          }
        }
      }
      .margin_auto {
        text-align: center;
        margin-top: 10px;
      }
      .text_center_certification {
        text-align: center;
        margin-top: 20px;
      }
      .wrap_img {
        text-align: center;
        img {
          width: 60px;
          height: 60px;
          border-radius: 50%;
        }
      }
      .ef_form_flex {
        font-size: 20px;
        padding: 25px 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span:nth-child(2) {
          font-size: 15px;
          cursor: pointer;
          &:hover {
            opacity: 0.75;
          }
        }
      }
    }
  }
  .withdraw {
    position: relative;
    .verification {
      position: absolute;
      left: 0;
      top: 42px;
      z-index: 2;
      font-size: 12px;
      display: inline-block;
      line-height: 20px;
      color: #c0c4cc;
    }
  }

  .labelRemarksAssetCenter {
    margin-top: 40px;
  }
}
</style>
